import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonModal
} from "@ionic/react";
import { format, parseISO } from "date-fns";
import { onSnapshot, orderBy, query, startAt, where } from "firebase/firestore";
import { addSharp } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import EventItem from "../components/EventItem";
import EventList from "../components/EventList";
import EventModal from "../components/EventModal";
import OrganisationSegment from "../components/OrganisationSegment";
import { AuthContext } from "../providers/AuthProvider";
import { eventsCollection } from "../services/firebase";

export default function CalendarPage() {
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const { selectedOrganisation } = useContext(AuthContext);
  const [presentEventModal, dismissEventModal] = useIonModal(<EventModal dismiss={() => dismissEventModal()} />);

  useEffect(() => {
    if (selectedOrganisation) {
      const today = format(new Date(), "yyyy-MM-dd");
      handleDateChange(today);
    }
  }, [selectedOrganisation]);

  function formatDate(value) {
    return value && format(parseISO(value), "dd/MM/yyyy");
  }

  function getEvents(date) {
    const selected = format(parseISO(date), "yyyy-MM-dd");
    const todaysDate = new Date(selected);
    todaysDate.setDate(todaysDate.getDate() - 365);
    const fetchFromDate = format(todaysDate, "yyyy-MM-dd");
    const q = query(
      eventsCollection,
      where("organisation", "==", selectedOrganisation),
      orderBy("start"),
      startAt(fetchFromDate)
    );
    onSnapshot(q, snapshot => {
      const todays = [];
      snapshot.forEach(doc => {
        const event = { id: doc.id, ...doc.data() };
        const start = format(parseISO(event.start), "yyyy-MM-dd");
        const end = format(parseISO(event.end), "yyyy-MM-dd");
        if (end >= selected && start <= selected) {
          todays.push(event);
        }
      });
      setEvents(todays);
    });
  }

  function handleDateChange(date) {
    setSelectedDate(date);
    getEvents(date);
  }

  return (
    <IonPage className="calendar-page">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Kalender</IonTitle>

          <IonButtons slot="end">
            <IonButton onClick={() => presentEventModal()}>
              <IonIcon slot="icon-only" icon={addSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <OrganisationSegment />
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol size-md="6">
              <IonDatetime
                locale="da-DK"
                presentation="date"
                firstDayOfWeek="1"
                size="cover"
                mode="md"
                onIonChange={e => handleDateChange(e.detail.value)}
              />
              <IonList className="event-list">
                <IonListHeader color="secondary">
                  <IonLabel>{formatDate(selectedDate)}</IonLabel>
                </IonListHeader>
                {events.length ? (
                  events.map(event => {
                    return <EventItem event={event} key={event.id} />;
                  })
                ) : (
                  <IonItem>
                    <IonLabel>Ingen begivenheder</IonLabel>
                  </IonItem>
                )}
              </IonList>
            </IonCol>
            <IonCol size-md="6">
              <EventList />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
