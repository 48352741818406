import { IonItem, IonLabel, IonListHeader } from "@ionic/react";
import { format } from "date-fns";
import { onSnapshot, orderBy, query, startAt, where } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../providers/AuthProvider";
import { eventsCollection } from "../services/firebase";
import EventItem from "./EventItem";

export default function AbsenceList() {
  const [todaysEvents, setTodaysEvents] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);
  const { selectedOrganisation } = useContext(AuthContext);

  function getPreviousMonday() {
    const todaysDate = new Date();
    const today = new Date(format(todaysDate, "yyyy-MM-dd"));
    const weekStart = new Date(format(todaysDate, "yyyy-MM-dd"));
    weekStart.setDate(today.getDate() - ((today.getDay() + 6) % 7));
    const weekEnd = new Date(format(weekStart, "yyyy-MM-dd"));
    weekEnd.setDate(weekEnd.getDate() + 7);
    return { weekStart, weekEnd };
  }

  useEffect(() => {
    let unsubscribe;
    if (selectedOrganisation) {
      const todaysDate = new Date();
      const { weekStart, weekEnd } = getPreviousMonday();
      todaysDate.setDate(todaysDate.getDate() - 365);
      const fetchFromDate = format(todaysDate, "yyyy-MM-dd");
      const q = query(
        eventsCollection,
        where("organisation", "==", selectedOrganisation),
        orderBy("start"),
        startAt(fetchFromDate)
      );
      unsubscribe = onSnapshot(q, snapshot => {
        const todays = [];
        const future = [];
        snapshot.forEach(doc => {
          const event = { id: doc.id, ...doc.data() };
          const start = new Date(event.start);
          const end = new Date(event.end);

          if (end >= weekStart && end <= weekEnd) {
            todays.push(event);
          } else if (weekEnd < start) {
            future.push(event);
          }
        });
        setTodaysEvents(todays);
        setFutureEvents(future);
      });
    }
    return unsubscribe;
  }, [selectedOrganisation]);
  return (
    <>
      <IonListHeader color="secondary">
        <IonLabel>Denne uge</IonLabel>
      </IonListHeader>
      {todaysEvents?.length ? (
        todaysEvents.map(event => {
          return <EventItem event={event} key={event.id} />;
        })
      ) : (
        <IonItem>
          <IonLabel>Ingen begivenheder</IonLabel>
        </IonItem>
      )}
      <section className="future">
        <IonListHeader color="secondary">
          <IonLabel>Kommende</IonLabel>
        </IonListHeader>
        {futureEvents?.length ? (
          futureEvents.map(event => {
            return <EventItem event={event} key={event.id} />;
          })
        ) : (
          <IonItem>
            <IonLabel>Ingen begivenheder</IonLabel>
          </IonItem>
        )}
      </section>
    </>
  );
}
